import React, {useEffect, useState} from 'react'
import {Switch, Route} from 'react-router'
import AddIcon from '@mui/icons-material/Add'
import Login from '../pages/login'
import SearchPage from '../pages/search'
import {Box, Fab, Menu, MenuItem, Modal} from '@mui/material'
import CreateUserPage from '../pages/createUser'
import {ModalPage} from './ModalPage'
import CreatePatientPage from '../pages/createPatient'
import CreateDevicePage from '../pages/createDevice'
import {useDispatch, useSelector} from 'react-redux'
import {RootState} from '../redux/rootReducer'
import {actions as authActions} from '../redux/modules/auth'
import Device from '../models/Device'
import Patient from '../models/Patient'
import ResetPasswordPage from '../pages/resetPassword'

const Nav: React.FC = () => {
  const user = useSelector(({auth}: RootState) => auth.user)
  const dispatch = useDispatch()
  const [currentModalPage, setCurrentModalPage] = useState<ModalPage | undefined>(undefined)
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null)
  const showModal = !!currentModalPage
  const errorMessage = useSelector(({ patients }: RootState) => patients.error);

  useEffect(() => {
    if (errorMessage) {
      alert(`${errorMessage}`);
    }
  }, [errorMessage]);


  const closeMenu = () => {
    setMenuAnchorEl(null)
  }
  const openModal = (page: ModalPage) => {
    setCurrentModalPage(page)
    closeMenu()
  }
  const closeModal = () => {
    setCurrentModalPage(undefined)
  }
  const logout = () => {
    dispatch(authActions.logout())
  }

  return (
    <>
      <Modal
        sx={{padding: 5}}
        open={showModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <>{currentModalPage}</>
      </Modal>
      {user && (
        <Box sx={{textAlign: 'right', padding: 2}}>
          <Fab
            color="primary"
            variant="extended"
            aria-label="add"
            onClick={e => setMenuAnchorEl(e.currentTarget)}>
            <AddIcon />
          </Fab>
          <Fab sx={{marginLeft: 2}} color="inherit" variant="extended" onClick={logout}>
            Log out
          </Fab>
          <Menu
            id="basic-menu"
            anchorEl={menuAnchorEl}
            open={!!menuAnchorEl}
            onClose={closeMenu}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}>
            <MenuItem
              onClick={() => openModal(() => <CreatePatientPage onClickCancel={closeModal} />)}>
              Add Patient
            </MenuItem>
            <MenuItem
              onClick={() => openModal(() => <CreateUserPage onClickCancel={closeModal} />)}>
              Add User
            </MenuItem>
          </Menu>
        </Box>
      )}
      <Switch>
        <Route exact path="/login">
          <Login />
        </Route>
        <Route exact path="/">
          <SearchPage
            onClickAddDevice={(patientId: string) =>
              openModal(() => <CreateDevicePage onClickCancel={closeModal} patientId={patientId} />)
            }
            onClickEditDevice={(device: Device) =>
              openModal(() => <CreateDevicePage onClickCancel={closeModal} device={device} />)
            }
            onClickEditPatient={(patient: Patient) =>
              openModal(() => <CreatePatientPage onClickCancel={closeModal} patient={patient} />)
            }
          />
        </Route>
        <Route exact path="/resetPassword/:userId/:resetPasswordToken">
          <ResetPasswordPage />
        </Route>
      </Switch>
    </>
  )
}
export default Nav
