import autoBind from 'auto-bind'
import Patient from 'models/Patient'
import Device from 'models/Device'
import Usage from 'models/Usage'
import {Environment} from './environments'
import Activity from 'models/Activity'
import User from 'models/User'
import Pain from 'models/Pain'
import {SearchResult} from 'redux/modules/search'

enum Method {
  Get = 'GET',
  Post = 'POST',
  Patch = 'PATCH',
  Put = 'PUT',
  Delete = 'DELETE',
}

export const UnauthorizedError = new Error('Unauthorized')
export const ForbiddenError = new Error('Forbidden')
export const BadRequest = new Error('Bad Request')
export const Maintenance = new Error('Maintenance')

class Api {
  environment: Environment
  token?: string

  /**
   * Creates a new Api object for leveraging the backend REST webservice powering this admin app
   * @param environment the api environment to use
   */
  constructor(environment: Environment) {
    autoBind(this)
    this.environment = environment
  }

  setToken(token: string | undefined) {
    this.token = token
  }

  async login(email: string, password: string) {
    try {
      const response = await this.request(Method.Post, '/login', {email, password})
      return new User(response)
    } catch (error) {
      let response = {status: 'error', message: 'Not Provided', code: 500}
      if (error === UnauthorizedError) {
        response.message = 'Invalid email or password'
        response.code = 401
      } else if (error === ForbiddenError) {
        response.message = "You don't have permissions to access"
        response.code = 403
      } else if (error === Maintenance) {
        response.message = "The app is under maintenance, please try again later."
        response.code = 503
      }
      return response
    }
  }

  async search(query: string): Promise<SearchResult> {
    const response = await this.request(Method.Post, '/search', {query})
    return {
      users: response.users.map((json: any) => new User(json)),
      devices: response.devices.map((json: any) => new Device(json)),
      patients: response.patients.map((json: any) => new Patient(json)),
    }
  }

  async getAllPain() {
    const response = await this.request(Method.Get, '/pain/all')
    return response.map((json: any) => new Pain(json))
  }

  async getPain(patientId: string) {
    const response = await this.request(Method.Get, `/pain?patientId=${patientId}`)
    return response.map((json: any) => new Pain(json))
  }

  async getUsers() {
    const response = await this.request(Method.Get, '/users')
    return response.map((json: any) => new User(json))
  }

  async createUser(user: User) {
    await this.request(Method.Post, '/users', user.asJson())
  }

  async getAllActivity() {
    const response = await this.request(Method.Get, '/activity')
    return response.map((json: any) => new Activity(json))
  }

  async getActivity(deviceId: string) {
    const response = await this.request(Method.Get, `/devices/${deviceId}/activity`)
    return response.map((json: any) => new Activity(json))
  }

  async getAllUsage() {
    const response = await this.request(Method.Get, '/usage')
    return response.map((json: any) => new Usage(json))
  }

  async getUsage(deviceId: string) {
    const response = await this.request(Method.Get, `/devices/${deviceId}/usage`)
    return response.map((json: any) => new Usage(json))
  }

  async getDevices() {
    const response = await this.request(Method.Get, '/devices')
    return response.map((json: any) => new Device(json))
  }

  async addDevice(device: Device, patientId: string) {
    const response = await this.request(Method.Post, '/devices', {
      name: device.name,
      udid: device.udid,
      patientId: patientId,
    })
    return new Device(response)
  }

  async deleteDevice(id: string) {
    await this.request(Method.Delete, `/devices/${id}`)
  }

  async getPatients() {
    try {
      const response = await this.request(Method.Get, '/patients');
      return response.map((json: any) => new Patient(json));
    } catch (error) {
      let response = { status: 'error', message: 'Not Provided', code: 500 };
      if (error === Maintenance) {
        response.message = "The app is under maintenance, please try again later.";
        response.code = 503;
      } 
      return response;
    }
  }

  async addPatient(patient: Patient) {
    const response = await this.request(Method.Post, '/patients', {
      firstName: patient.firstName,
      lastName: patient.lastName,
      physicianName: patient.physicianName,
      birthdate: patient.birthdate.toString(),
    })
    return new Patient(response)
  }

  async deletePatient(id: string) {
    await this.request(Method.Delete, `/patients/${id}`)
  }

  async updateDevice(device: Device) {
    await this.request(Method.Patch, `/devices/${device.id}`, {
      name: device.name,
      udid: device.udid,
    })
  }

  async updatePatient(patient: Patient) {
    await this.request(Method.Patch, `/patients/${patient.id}`, {
      firstName: patient.firstName,
      lastName: patient.lastName,
      physicianName: patient.physicianName,
      birthdate: patient.birthdate.toString(),
      email: patient.email,
    })
  }

  async sendResetPasswordInstructions(id: string): Promise<any> {
    return await this.request(
      Method.Post,
      `/passwordRecovery/sendResetPasswordInstructions?userId=${id}`,
    )
  }

  async resetUserPassword(id: string, newPassword: string, resetPasswordToken: string) {
    try {
      return await this.request(Method.Post, `/passwordRecovery/resetPassword?userId=${id}`, {
        passcode: resetPasswordToken,
        newPassword: newPassword,
      })
    } catch (error) {
      let response = {status: 'error', message: 'Not Provided', code: 500}
      if (error === BadRequest) {
        let errorMessage: any = error
        if (
          errorMessage.message === 'Incorrect or expired passcode. Please re-enter the passcode.'
        ) {
          response.message = 'Your password reset link expired, please request a new one.'
        } else {
          response.message = errorMessage.message
        }
        response.code = 400
      }
      return response
    }
  }

  /** @private */
  async request(method: Method, endpoint: string, json?: object): Promise<any> {
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: this.token,
    }
    console.log(headers)
    const params: any = {
      method,
      headers,
    }
    if (json) {
      params.body = JSON.stringify(json)
      console.log(params.body)
    }
    const url = `${this.environment.url}${endpoint}`
    console.log(url)
    const response = await fetch(url, params)
    if (response.ok) {
      return await response.json()
    }
    if (response.status === 401) {
      throw UnauthorizedError
    }
    if (response.status === 403) {
      throw ForbiddenError
    }
    if (response.status === 400) {
      const errorResponse = await response.json()
      const errorMessage = errorResponse.error
      BadRequest.message = errorMessage
      throw BadRequest
    }
    if (response.status === 503) {
      Maintenance.message = 'The app is under maintenance, please try again later.'
      throw Maintenance
    }
    let errorMessage =
      response.statusText ||
      'There was problem communicating with our servers, please try again later.'
    try {
      const errorJson = await response.json()
      errorMessage = errorJson.error
    } catch (_) {}

    throw new Error(errorMessage)
  }
}

export default Api
